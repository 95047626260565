export default [
  {
    title: 'Fin statistics',
    icon: 'CreditCardIcon',
    route: 'fin-statistics',
  },
  {
    title: 'Users',
    route: 'users-beta',
    icon: 'UserIcon',
  },
]
