<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="45"
            variant="light-primary"
          >
            <UserIcon />
          </b-avatar>
          <div class="d-sm-flex d-none user-nav mr-0 ml-1">
            <p class="user-name font-weight-bolder mb-0">
              {{ me.firstName }} {{ me.lastName }}
            </p>
          </div>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="tagsAction()"
        >
          <feather-icon
            v-if="showTags"
            size="16"
            icon="EyeOffIcon"
            class="mr-50"
          />
          <feather-icon
            v-else
            size="16"
            icon="EyeIcon"
            class="mr-50"
          />

          <span>{{ showTags ? 'Hide' : 'Show' }} tags</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { UserIcon } from 'vue-feather-icons'
import { get } from 'vuex-pathify'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    UserIcon,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...get('auth', ['me']),
    ...get('app', ['showTags']),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    tagsAction() {
      if (this.showTags) {
        this.$store.dispatch('app/hideTags')
      } else {
        this.$store.dispatch('app/showTags')
      }
    },
  },
}
</script>
