export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Accounts',
    route: 'accounts-ext',
    icon: 'KeyIcon',
  },
]
